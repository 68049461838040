import clsx from 'clsx';
import tailwind from 'utils/breakpoints';
import { formatNumber } from 'utils/number';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  rating: number;
  theme?: 'default' | 'transparent';
  size?: number;
  fontColor?: string;
  fontSize?: string;
  isPrelimiary?: boolean;
  customStyle?: string;
  overrideInnerCircleColor?: string;
  overrideOuterCircleColor?: string;
};

const QunoscoreRating = ({
  rating,
  theme = 'default',
  size = 64,
  fontColor = 'text-tertiary',
  fontSize = 'text-3xl',
  isPrelimiary = false,
  customStyle,
  overrideInnerCircleColor,
  overrideOuterCircleColor,
}: Props): JSX.Element => {
  const baseSize = size;
  const center = baseSize / 2;
  const radius = center - 4;
  const fullCircle = 2 * Math.PI * radius;
  const ratingCircle = fullCircle - (rating * fullCircle) / 10;
  const { locale } = useTranslation();

  return (
    <div className="relative">
      <svg
        height={baseSize}
        width={baseSize}
        viewBox={`0 0 ${baseSize} ${baseSize}`}
      >
        <circle
          stroke={(() => {
            if (isPrelimiary) {
              return theme === 'transparent'
                ? tailwind.theme.backgroundColor.silverLight
                : tailwind.theme.backgroundColor.silverLighter;
            }

            if (overrideOuterCircleColor) {
              return overrideOuterCircleColor;
            }

            return tailwind.theme.backgroundColor.secondary;
          })()}
          fill="transparent"
          strokeWidth="2"
          style={{ opacity: 0.2 }}
          r={center - 2}
          cx={center}
          cy={center}
        />
        <circle
          stroke={(() => {
            if (isPrelimiary) {
              return theme === 'transparent'
                ? tailwind.theme.backgroundColor.siam
                : tailwind.theme.backgroundColor.silverLighter;
            }

            if (overrideInnerCircleColor) {
              return overrideInnerCircleColor;
            }

            return tailwind.theme.backgroundColor.secondary;
          })()}
          fill="transparent"
          strokeWidth="2"
          strokeDasharray={`${fullCircle} ${fullCircle}`}
          r={radius}
          cx={center}
          cy={center}
          strokeDashoffset={ratingCircle}
          style={{ transformOrigin: '50% 50%', transform: 'rotate(-90deg)' }}
        />
      </svg>
      <div
        className={clsx(
          'absolute transform -translate-y-1/2 -translate-x-1/2 font-netto',
          theme === 'default'
            ? [isPrelimiary ? 'text-silverLighter' : fontColor]
            : 'text-black',
          fontSize,
          customStyle,
        )}
        style={{ left: '50%', top: '50%' }}
      >
        {formatNumber(rating.toFixed(1), locale)}
      </div>
    </div>
  );
};

export default QunoscoreRating;
