import { IMGS_PATH } from 'constants/image';
import clsx from 'clsx';
import { getYear } from 'date-fns';
import QunoscoreRating from 'components/Pages/DoctorProfile/QunoscoreRating';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import Link from 'components/ui/Link';
import tailwind from 'utils/breakpoints';
import { formatNumber } from 'utils/number';
import { getQunoScoreStatus } from 'utils/qunoScore';
import { useTranslation } from 'hooks/useTranslation';

export type PopularDoctorsSliderItemProps = {
  image?: string;
  objectPosition?: string;
  qunoscore?: number;
  speciality?: string;
  name: string | { de?: string; other?: string };
  location?: string;
  treatmentsPerformedLastYear?: number;
  yearsOfExperience?: number;
  slug?: string;
  qunoscoreIsPreliminary?: boolean;
  show?: string[];
};

type Props = {
  item: PopularDoctorsSliderItemProps;
  className?: string;
};

const PopularDoctorsSliderItem = ({ item, className }: Props): JSX.Element => {
  const { t, locale } = useTranslation();

  const getItemName = (): string => {
    const { name } = item;
    if (typeof name === 'string') {
      return name;
    }

    if (name.de && locale === 'de') {
      return name.de;
    }

    if (name.other) {
      return name.other;
    }

    return '';
  };

  const nameOrDefault = getItemName();

  return (
    <div className={clsx('rounded overflow-hidden pb-1', className)}>
      <div className="border my-8 sm:my-0 rounded relative">
        <div className="full-lazyload w-full h-56 relative overflow-hidden bg-doctorBg">
          {item.image ? (
            <img
              src={
                item.image.includes('https')
                  ? `${item.image}?w=414&h=349&f=face&fit=thumb&fm=jpg`
                  : `https:${item.image}?w=614&h=549&f=face&fit=thumb&fm=jpg`
              }
              className={clsx(
                'w-full h-full object-cover',
                item.objectPosition ? item.objectPosition : 'object-top',
              )}
              alt={nameOrDefault}
              height={499}
              width={464}
              loading="lazy"
            />
          ) : (
            <Image
              src={`${IMGS_PATH.PUBLIC}/doctor-placeholder.jpg`}
              className="h-full mx-auto object-cover object-top"
              fill
              alt={nameOrDefault}
            />
          )}
        </div>
        {!!item.qunoscore && (
          <div className="flex items-center p-3 bg-navy">
            <QunoscoreRating
              rating={item.qunoscore}
              size={48}
              fontColor="text-tertiary"
              fontSize="text-xl"
            />
            <div className="mx-2">
              <p
                className={clsx(
                  item.qunoscoreIsPreliminary
                    ? 'text-silverLighter'
                    : 'text-white',
                  'font-bold text-center',
                )}
              >
                {t(getQunoScoreStatus(item.qunoscore))}
              </p>
              <p className="text-white text-xs">
                <span className="text-tertiary font-bold">Quno</span>Score
              </p>
            </div>
          </div>
        )}
        <div className="p-3">
          {item.speciality ? (
            <p className="uppercase text-sm font-bold">
              {t(`speciality.${item.speciality}`)}
            </p>
          ) : null}
          {nameOrDefault && (
            <div className="mb-6">
              <p className="font-netto text-navy text-1_2xl truncate">
                {nameOrDefault}
              </p>
              {item.location && (
                <p className="flex mt-1">
                  <span>
                    <Icon
                      className="inline-flex mr-3"
                      type="LocationPinAlternate"
                      color={tailwind.theme.backgroundColor.secondary}
                    />
                  </span>
                  <span className="truncate">{item.location}</span>
                </p>
              )}
            </div>
          )}
          {!!item.treatmentsPerformedLastYear && (
            <div className="mb-2">
              <p className="text-navy text-xl mb-1">
                {formatNumber(item.treatmentsPerformedLastYear, locale)}
              </p>
              {t('doctorsSliderItem.treatmentsPerformed', {
                year: getYear(new Date()) - 1,
              })}
            </div>
          )}
          {!!item.yearsOfExperience && (
            <div>
              <p className="text-navy text-xl">{item.yearsOfExperience}</p>
              {t('doctorsSliderItem.yearsOfExperience')}
            </div>
          )}
          {locale && item.slug && (
            <div className="flex font-netto mt-4">
              <Link href={`/${locale}/${item.slug}`}>
                <a
                  className="mx-1 flex-1 text-right py-2 text-primary underline transition-colors duration-300 ease-in-out stretched-link"
                  title={t('viewProfile')}
                >
                  {t('viewProfile')}
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopularDoctorsSliderItem;
