import { FunnelId } from 'constants/funnel';
import { IMGS_PATH } from 'constants/image';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import Button from 'components/Qds/Button';
import styles from './Hero.module.scss';
import HeroCarousel from './HeroCarousel';

const Hero = (): JSX.Element => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.heroGrid}>
          <div className={styles.heroLeftColumn}>
            <h1 className={styles.headline}>{t('home.hero.headline')}</h1>
            <p className={styles.text}>{t('home.hero.text')}</p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_1')}
            </p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_2')}
            </p>
            <p className={styles.usp}>
              <Icon className={styles.heroCheck} type="Check" color="#155263" />
              {t('home.hero.usp_3')}
            </p>
            <ExternalFunnelButton
              funnelId={FunnelId.CONTACT_FORM}
              renderButton={(openFunnel) => {
                return (
                  <Button
                    className="mt-8"
                    fullWidth="mobile"
                    size="large"
                    shadow={false}
                    onClick={() => {
                      trackEvent({
                        category: 'CTA',
                        action: 'CTA|Click',
                        label: 'CTA|Home|Hero|GetStarted',
                      });

                      openFunnel();
                    }}
                  >
                    <span className="md:px-28">{t('home.getStarted')}</span>
                  </Button>
                );
              }}
            />
          </div>
          <div className={styles.heroRightColumn}>
            <svg
              className={styles.blob}
              width="385"
              height="417"
              viewBox="0 0 385 417"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M356.17 300.49C343.54 340.52 318.97 372.48 282.48 396.36C245.98 420.23 208.79 423.39 170.89 405.84C132.99 388.28 93.16 368.79 51.40 347.37C9.64 325.95 -6.66 291.89 2.45 245.19C11.57 198.48 25.08 150.20 42.98 100.34C60.88 50.47 97.90 19.05 154.04 6.05C210.19 -6.93 264.06 0.96 315.64 29.76C367.22 58.55 390.03 100.86 384.07 156.70C378.10 212.53 368.80 260.46 356.17 300.49Z"
                fill="#ffca58"
              />
            </svg>
            <HeroCarousel
              slides={[
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-anja.png`,
                  icon: 'Balance',
                  speciality: {
                    en: 'weight-loss-surgery',
                    de: 'operative-gewichtsreduktion',
                  },
                  treatment: {
                    en: 'gastric-bypass-surgery',
                    de: 'magenbypass-operation',
                  },
                  treatmentLabel: t('treatment.gastricBypass'),
                  name: 'Anja',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.anja'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-michael.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                    nl: 'plastische-chirurgie',
                    es: 'cirugia-plastica',
                    it: 'chirurgia-plastica',
                    fr: 'chirurgie-esthetique',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                    nl: 'haartransplantatie',
                    es: 'injerto-capilar',
                    it: 'trapianto-di-capelli',
                    fr: 'greffe-de-cheveux',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Michael',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.michael'),
                  show: ['de', 'en', 'nl', 'es', 'it', 'fr'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-courtney.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'veneers',
                    de: 'veneers',
                  },
                  treatmentLabel: t('treatment.veneers'),
                  name: 'Courtney',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.courtney'),
                  show: ['de', 'en', 'fr', 'it', 'es', 'nl'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-coree.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'teeth-cleaning',
                    de: 'zahnreinigung',
                  },
                  treatmentLabel: t('treatment.teethCleaning'),
                  name: 'Coree',
                  country: t('countries.germany'),
                  text: t('home.hero.review.coree'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-carl.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'dental-crown',
                    de: 'zahnkrone',
                  },
                  treatmentLabel: t('treatment.dentalCrowns'),
                  name: 'Carl',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.carl'),
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-eamon.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                    nl: 'plastische-chirurgie',
                    es: 'cirugia-plastica',
                    it: 'chirurgia-plastica',
                    fr: 'chirurgie-esthetique',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                    nl: 'haartransplantatie',
                    es: 'injerto-capilar',
                    it: 'trapianto-di-capelli',
                    fr: 'greffe-de-cheveux',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Eamon',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.eamon'),
                  className: styles.carouselItem,
                  show: [],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-norman.png`,
                  icon: 'Bone',
                  speciality: {
                    en: 'orthopedics',
                    de: 'orthopaedie',
                  },
                  treatment: {
                    en: 'spine-surgery',
                    de: 'wirbelsaeulenchirurgie',
                  },
                  treatmentLabel: t('treatment.spineSurgery'),
                  name: 'Norman',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.norman'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-dionne.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'complete-abdominoplasty',
                    de: 'komplette-bauchdeckenplastik',
                  },
                  treatmentLabel: t('treatment.abdominoplasty'),
                  name: 'Dionne',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.dionne'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-emma.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'breast-augmentation',
                    de: 'brustvergroesserung',
                  },
                  treatmentLabel: t('treatment.breastaug'),
                  name: 'Emma',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.emma'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-andreas.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'gynecomastia-surgery',
                    de: 'gynaekomastie-chirurgie',
                  },
                  treatmentLabel: t('treatment.gynecomastiaSurgery'),
                  name: 'Andreas',
                  country: t('countries.germany'),
                  text: t('home.hero.review.andreas'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-ihfan.png`,
                  icon: 'Cardiology',
                  speciality: {
                    en: 'cardiovascular-procedures',
                    de: 'kardiovaskulaere-eingriffe',
                  },
                  treatmentLabel: t('cardiologyConsultation'),
                  name: 'Ihfan',
                  country: t('countries.germany'),
                  text: t('home.hero.review.ihfan'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-chris.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'hair-transplant',
                    de: 'haartransplantation',
                  },
                  treatmentLabel: t('treatment.hairTransplant'),
                  name: 'Chris',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.chris'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-robert.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'dental-crown',
                    de: 'zahnkrone',
                  },
                  treatmentLabel: t('treatment.dentalCrowns'),
                  name: 'Robert',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.robert'),
                  className: 'md:pl-12 md:pb-0',
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-taniya.png`,
                  icon: 'Tooth',
                  speciality: {
                    en: 'dentistry',
                    de: 'zahnmedizin',
                  },
                  treatment: {
                    en: 'veneers',
                    de: 'veneers',
                  },
                  treatmentLabel: t('treatment.veneers'),
                  name: 'Taniya',
                  country: t('countries.unitedKingdom'),
                  text: t('home.hero.review.taniya'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-alison.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'rhinoplasty',
                    de: 'nasenkorrektur',
                  },
                  treatmentLabel: t('treatment.rhinoplasty'),
                  name: 'Alison',
                  country: t('countries.ireland'),
                  text: t('home.hero.review.alison'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-roland.png`,
                  icon: 'Neurology',
                  speciality: {
                    en: 'neurology',
                    de: 'neurologie',
                  },
                  treatmentLabel: t('neurologyConsultation'),
                  name: 'Roland',
                  country: t('countries.germany'),
                  text: t('home.hero.review.roland'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-maddie.png`,
                  icon: 'PlasticSurgery',
                  speciality: {
                    en: 'plastic-surgery',
                    de: 'plastische-chirurgie',
                  },
                  treatment: {
                    en: 'sex-reassignment-surgery-male-to-female',
                    de: 'geschlechtsangleichende-operation-mann-zu-frau',
                  },
                  treatmentLabel: t(
                    'treatment.sexReassignmentSurgeryMaleFemale',
                  ),
                  name: 'Maddie',
                  country: t('countries.unitedStates'),
                  text: t('home.hero.review.maddie'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
                {
                  image: `${IMGS_PATH.PUBLIC}/home/blob-amalia.png`,
                  icon: 'Cardiology',
                  speciality: {
                    en: 'cardiovascular-procedures',
                    de: 'kardiovaskulaere-eingriffe',
                  },
                  treatmentLabel: t('treatment.heartSurgery'),
                  name: 'Amalia',
                  country: t('countries.romania'),
                  text: t('home.hero.review.amalia'),
                  className: styles.carouselItem,
                  show: ['de', 'en'],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
